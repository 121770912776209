import styled from "styled-components";

export const ReasonsContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 40px 0;
    align-items: center;
`;

export const ReasonsSectionHeader = styled.h2`
    color: #214697;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AllReasons = styled.div`
    display: flex;
    width: 80%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Reason = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    text-align: center;

    @media (max-width: 768px) {
        margin: 20px 0;
    }
    
    img {
        margin-bottom: 20px;
    }
    
    h3 {
        font-weight: 600;
        color: #214697;
        margin-bottom: 20px;
    }
`;