import styled from "styled-components";

export const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 0;
`;

export const FooterRow = styled.div`
    margin-bottom: 30px;
    min-width: 60%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: 768px) {
        width: 90%;
        direction: rtl;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;


export const FooterQuote = styled.p`
    font-size: 1.3rem;
    font-weight: 600;

    @media (max-width: 768px) {
        direction: ltr;
    }
`;

export const FooterLogo = styled.img`
    max-width: 150px;
    margin: auto;

    @media (max-width: 768px) {
        margin: 0 auto 0 0;
    }
`;

export const FooterLinks = styled.div`
    margin-left: auto;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const FooterLink = styled.a`
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: none;
    color: black;
`;

export const Socials = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

export const SocialLink = styled.a`
    margin: 0 10px;
`;

export const Copyright = styled.p`
    margin-bottom: 15px;
    font-size: 1.3rem;
`;

export const MobileFooterLinks = styled.div`
    display: none;
    color: #214697;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
    }
`;

export const MobileFooterLink = styled.a`
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin: 0 10px;
    color: #214697;
    text-decoration: none;
`;