import { observer } from "mobx-react-lite";
import { Modal } from "react-bootstrap";
import ManageSearch from "../../pages/manageSearch";
import {SITE_LOGO} from "../../constants/images";
import {useEffect, useState} from "react";
import {FunnelModalHeader, StepIndicator, StyledModal} from "./styles";
import {FormattedMessage} from "react-intl";

function FunnelModal({show, handleShow, selectedCity}) {
    const stepsAmount = 2;
    const [currentStep, setCurrentStep] = useState(1);

    const handleCurrentStepChange = (value) => {
        if (currentStep + value >= 0 && currentStep + value <= stepsAmount) {
            setCurrentStep(currentStep + value);
        }
    };

    const submitFunnel = (body) => {

    };

    useEffect(() => {
        if (!show) setCurrentStep(1);
    }, [show]);

    return (
        <StyledModal
            show={show}
            size="xl"
            onHide={() => handleShow(false)} 
            centered
        >
            <Modal.Header>
                <FunnelModalHeader>
                    <StepIndicator>
                        <FormattedMessage id="step" /> {currentStep}/{stepsAmount}
                    </StepIndicator>
                    <img src={SITE_LOGO} width={125} alt="form-site-logo" />
                    <button type="button" className="btn-close" onClick={() => handleShow(false)} />
                </FunnelModalHeader>
            </Modal.Header>
            <Modal.Body>
                <ManageSearch
                    selectedCity={selectedCity}
                    currentStep={currentStep}
                    stepsAmount={stepsAmount}
                    handleCurrentStepChange={handleCurrentStepChange}
                    submitFunnel={submitFunnel}
                />
            </Modal.Body>
        </StyledModal>
    );
}

export default observer(FunnelModal);