import {observer} from "mobx-react-lite";
import {
    Copyright,
    FooterLink,
    FooterLinks,
    FooterLogo,
    FooterQuote,
    FooterRow, MobileFooterLink, MobileFooterLinks,
    SocialLink,
    Socials,
    StyledFooter
} from "./styles";
import {INSTAGRAM, SITE_LOGO, TIKTOK} from "../../constants/images";
import {FormattedMessage} from "react-intl";

function Footer() {
    return (
        <StyledFooter>
            <FooterRow>
                <FooterQuote>
                    <FormattedMessage
                        id="footer-quote"
                        values={{
                            break: <><br></br></>
                        }}
                    />
                </FooterQuote>
                <FooterLogo src={SITE_LOGO} alt="footer-logo"/>
                <FooterLinks>
                    <FooterLink href=""><FormattedMessage id="terms-and-conditions"/></FooterLink> <br/>
                    <FooterLink href=""><FormattedMessage id="privacy-statement"/></FooterLink> <br/>
                    <FooterLink href="/contact"><FormattedMessage id="contact"/></FooterLink>
                </FooterLinks>
            </FooterRow>
            <Socials>
                <SocialLink href="https://www.instagram.com/therentfriend.nl/" target="_blank">
                    <img src={INSTAGRAM} alt="instagram" />
                </SocialLink>
                <SocialLink href="https://www.tiktok.com/@therentfriend" target="_blank">
                    <img src={TIKTOK} alt="instagram" />
                </SocialLink>
            </Socials>
            <Copyright>{new Date().getFullYear()} The Rent Friend</Copyright>
            <MobileFooterLinks>
                <MobileFooterLink href=""><FormattedMessage id="mobile-privacy-statement"/></MobileFooterLink>•
                <MobileFooterLink href="/contact"><FormattedMessage id="contact"/></MobileFooterLink>•
                <MobileFooterLink href=""><FormattedMessage id="mobile-terms-and-conditions"/></MobileFooterLink>
            </MobileFooterLinks>
        </StyledFooter>
    );
}

export default observer(Footer);