import {observer} from "mobx-react-lite";
import {SubscriptionsSection, SubscriptionsSectionHeader} from "./styles";
import {FormattedMessage} from "react-intl";
import Subscriptions from "../../components/subscriptions";
import Intro from "../../components/intro";
import Guarantees from "../../components/guarantees";
import Banner from "../../components/banner";
import Faq from "../../components/faq";
import Reasons from "../reasons";
import Footer from "../../components/footer";

function Home() {
    return (
        <>
            <Intro />
            <Guarantees />
            <Banner />
            <Reasons />
            <SubscriptionsSection>
                <SubscriptionsSectionHeader>
                    <span id="desktop"><FormattedMessage id="subscriptions-section-header"/></span>
                    <span id="mobile"><FormattedMessage id="subscriptions-section-header-mobile"/></span>
                </SubscriptionsSectionHeader>
                <Subscriptions/>
            </SubscriptionsSection>
            <Faq questionNumbers={[1, 2, 3, 4, 5, 6, 7]} />
            <Footer />
        </>
    );
}

export default observer(Home);