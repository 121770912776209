const SPECIAL_CHARACTER_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const PASSWORD_LENGTH = (password) => password.length >= 10;
export const PASSWORD_SPECIAL_CHARACTER = (password) => SPECIAL_CHARACTER_REGEX.test(password);
export const PASSWORD_CAPITAL_LETTER = (password) => password !== password.toLowerCase();
export const PASSWORD_NUMBER = (password) => /\d/.test(password);

export const IS_VALID_PASSWORD = (password) => PASSWORD_LENGTH(password) && PASSWORD_SPECIAL_CHARACTER(password) && PASSWORD_CAPITAL_LETTER(password) && PASSWORD_NUMBER(password);

export const IS_EMAIL_VALID = (email) => EMAIL_REGEX.test(email);