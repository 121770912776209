import {authToken, BASE_URL, WORKER_URL} from "../constants/api";

export const Subscription = {
    all: async () => {
        const res = await fetch(`${BASE_URL}/subscriptions`, {
            method: "GET"
        });
        return await res.json();
    },
    cancel: async () => {
        try {
            const res = await fetch(`${WORKER_URL}/cancel-subscription`, {
                method: "POST",
                headers: {
                    'x-auth-token': authToken
                }
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
};