import {makeAutoObservable, reaction} from "mobx";
import {messages} from "../constants/locale";
import {createBrowserHistory} from "history";

export class LocaleStore {
    // value = null;
    value = "nl";
    history = createBrowserHistory();

    constructor() {
        makeAutoObservable(this);

        // const basename = this.history.location.pathname.split('/')[1];
        //
        // if (Object.keys(messages).includes(basename)) {
        //     this.setValue(basename);
        // } else {
        //     this.setValue("nl");
        //     this.reloadWithLocale("nl");
        // }
        //
        // reaction(
        //     () => this.value,
        //     (newValue) => {
        //         this.reloadWithLocale(newValue);
        //     }
        // );
    }

    setValue(val) {
        this.value = val;
    }

    reloadWithLocale(locale) {
        const currentUrl = new URL(window.location.href);
        const pathSegments = currentUrl.pathname.split('/');
        pathSegments[1] = locale;
        const newPath = pathSegments.join('/');
        this.history.replace(`${newPath}${currentUrl.search}${currentUrl.hash}`);
        window.location.reload();
    }
}