import {observer} from "mobx-react-lite";
import {
    CreateSearchBtn, FreeUserActivateBtn,
    FreeUserBanner,
    ProfileContainer,
    ProfileContent,
    ProfileSection,
    ProfileSectionHeader, RightSection
} from "./styles";
import {FormattedMessage, useIntl} from "react-intl";
import Searches from "../../components/searches";
import Matches from "../../components/matches";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {userStore} from "../../stores";
import Management from "../../components/management";

function Profile() {
    const intl = useIntl();
    const navigate = useNavigate();
    const startCreate = () => navigate("/create");

    useEffect(() => {
        userStore.initToken().then();
    }, []);

    return (
        <ProfileContainer>
            {userStore.type === "user-free" && (
                <FreeUserBanner>
                    <b>{intl.formatMessage({id: "account-inactive"})}</b>
                    <span id="desktop-user-free-ext">
                        <FormattedMessage id="user-free-banner-text"/>
                    </span>
                    <FreeUserActivateBtn onClick={() => navigate("/subscriptions")}>
                        <FormattedMessage id="subscription-card-activate"/>
                    </FreeUserActivateBtn>
                </FreeUserBanner>
            )}
            <ProfileContent>
                <CreateSearchBtn onClick={startCreate}>
                    <span>+</span><FormattedMessage id="create-new-search"/>
                </CreateSearchBtn>
            </ProfileContent>
            <ProfileContent>
                <div>
                    <ProfileSection>
                        <ProfileSectionHeader>
                            <FormattedMessage id="table-searches-header"/>
                        </ProfileSectionHeader>
                        <Searches/>
                    </ProfileSection>

                    <ProfileSection>
                        <ProfileSectionHeader>
                            <FormattedMessage id="table-matches-header"/>
                        </ProfileSectionHeader>
                        <Matches/>
                    </ProfileSection>
                </div>
                {/*<RightSection>*/}
                {/*    <ProfileSection >*/}
                {/*        <ProfileSectionHeader>*/}
                {/*            <FormattedMessage id="management" />*/}
                {/*        </ProfileSectionHeader>*/}
                {/*        <Management />*/}
                {/*    </ProfileSection>*/}
                {/*</RightSection>*/}
            </ProfileContent>
        </ProfileContainer>
    );
}

export default observer(Profile);