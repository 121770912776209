import styled from "styled-components";
import {SubInfo, SubPrice} from "./styles";

export const MobileSubscriptionCardContainer = styled.div`
    width: 100%;
    margin: 10px 0;
    display: none;
    border: 1px solid ${props => props.selected ? "#214697" : "#333333"};
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
    }
`;

export const MobileSubSearchesInfo = styled.div`
    margin-left: 20px;
    margin-right: 10px;
`;

export const MobileAmountSearches = styled.div`
    display: flex;
    align-items: center;
    
    p {
        margin-left: 8px;
    }

    @media (max-width: 485px) {
        img {
            display: none;
        }

        p {
            margin: 0;
        }
    }
`;

export const MobileSubDuration = styled.label`
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1.7rem;
    display: inline-block;
`;

export const MobileSubPriceInfo = styled.div`
    margin-left: auto;
`;

export const MobileSubInfo = styled(SubInfo)`
    margin-bottom: 0;
`;

export const MobileSubPrice = styled(SubPrice)`
    position: relative;
    justify-content: center;
    
    .currency {
        position: absolute;
        right: 100%;
        transform: translateY(3px);
    }
    
    p {
        font-size: 2rem;
    }
`;