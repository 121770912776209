import styled from "styled-components";

export const BannerContainer = styled.section`
    display: flex;
    padding: 40px 20%;
    background: #214697;
    justify-content: center;
    width: 100%;

    #banner-text-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        padding: 40px 5%;
        
        #banner-text-desktop {
            display: none;
        }
        
        #banner-text-mobile {
            display: block;
        }
    }
`;

export const BannerText = styled.p`
    color: white;
    text-align: center;
`;