import styled from "styled-components";
import {Link} from "react-router-dom";

export const AuthContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;

    @media (max-width: 768px) {
        margin: 0;
    }
`;

export const AuthFormContainer = styled.div`
    padding: 30px 30px 40px 30px;
    border: 1px solid #f2f2f2;
    border-radius: 15px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        width: 90%;
        border: none;
    }
`;

export const AuthFormTitle = styled.h2`
    margin: 15px 0 35px 0;
    font-weight: 600;
    text-align: center;
`;

export const AuthForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const FormRow = styled.div`
    display: flex;
    width: 100%;
    
    div {
        flex: 1;
    }

    div:nth-child(odd) {
        margin-right: 5px;
    }

    div:nth-child(even) {
        margin-left: 5px;
    }
`;

export const AuthFormItem = styled.div`
    margin-bottom: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const AuthSubmitButton = styled.button`
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 25px;
    color: white;
    background: #214697;
    font-weight: 600;
    width: 100%;
`;

export const ForgotPasswordLink = styled(Link)`
    margin-top: 10px;
    margin-left: auto;
    display: inline-block;
    font-size: 1.4rem;
    text-decoration: none;
    color: #214697;
`;