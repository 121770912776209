import {IntroButton, IntroButtonSubText, StartSearchContainer} from "../intro/styles";
import {userStore} from "../../stores";
import {FormattedMessage} from "react-intl";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {log} from "../../constants/firebase";

function StartSearchDispleased() {
    const navigate = useNavigate();

    const onIntroButtonClick = () => {
        log("sign_up", {event_name: "from_home"});
        navigate(userStore.authenticated ? "/create" : "/register");
    };

    return (
        <StartSearchContainer>
            <IntroButton onClick={onIntroButtonClick}>
                <FormattedMessage id="intro-btn-text"/>
            </IntroButton>
            <IntroButtonSubText>
                <FormattedMessage id="subscription-displeased"/>
                {` - `}
                <span onClick={() => navigate("/subscriptions")}>
                        <FormattedMessage id="intro-show-prices"/>
                    </span>
            </IntroButtonSubText>
        </StartSearchContainer>
    );
}

export default observer(StartSearchDispleased);