import {observer} from "mobx-react-lite";
import {
    BackStepBtn,
    CreateUpdateSearch,
    DetailsSection, FunnelStepsButtons,
    LocationSection,
    ManageSearchContainer,
    ManageSearchForm,
    SearchDropdownContainer,
    SelectLabel,
    StyledSelect
} from "./styles";
import Map from "../../components/map";
import {useEffect, useState} from "react";
import {SearchClient} from "../../agent/search";
import {DISTANCES, NR_BEDROOMS, SIZES, TYPES} from "../../constants/search";
import PriceSlider from "../../components/priceSlider";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {userStore} from "../../stores";
import {Bounce, toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {FormattedMessage, useIntl} from "react-intl";

function ManageSearch(
    {
        selectedCity = "",
        currentStep = -1,
        stepsAmount = 0,
        handleCurrentStepChange = (value) => {},
        submitFunnel = (body) => {}
    }
) {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const isEdit = location.pathname.includes("edit");
    const {id} = useParams();
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(selectedCity);
    const [radius, setRadius] = useState(1);
    const [priceRange, setPriceRange] = useState([400, 3000]);
    const [bedrooms, setBedrooms] = useState(1);
    const [roomSize, setRoomSize] = useState(10);
    const [roomType, setRoomType] = useState("furnished");

    const onLocationChange = (e) => {
        const key = e.target.value;
        if (key !== "") setSelectedLocation(key);
    };

    const handlePriceRangeChange = (value) => setPriceRange(value);

    const setEditView = () => {
        const selectedSearch = userStore.searches.find(s => s["unique_identifier"] === id);
        setSelectedLocation(selectedSearch.location);
        setRadius(selectedSearch.radius);
        setPriceRange([selectedSearch.min, selectedSearch.max]);
        setBedrooms(selectedSearch["bedrooms_min"]);
        setRoomSize(selectedSearch["square_meters_min"]);
        setRoomType(selectedSearch["furnished_preference"]);
    };

    useEffect(() => {
        SearchClient.locations().then(res => {
            setLocations(res);
            if (isEdit) setEditView();
        });
    }, []);

    const showErrorToast = (msg) => {
        toast.error(msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    };

    const onManageSuccess = () => navigate("/profile");

    const addSearch = (body) => {
        userStore.addSearch(body).then(res => {
            const [success, message] = res;
            if (success) onManageSuccess();
            else showErrorToast(message);
        });
    };

    const updateSearch = (body) => {
        userStore.updateSearch(body).then(res => {
            const [success, message] = res;
            if (success) onManageSuccess();
            else showErrorToast(message);
        });
    }

    const getBody = () => {
        return {
            "location": selectedLocation,
            "lat": locations[selectedLocation].lat,
            "long": locations[selectedLocation].long,
            "radius": parseInt(radius),
            "rent_min": priceRange[0],
            "rent_max": priceRange[1],
            "square_meters_min": parseInt(roomSize),
            "bedrooms_min": parseInt(bedrooms),
            "furnished_preference": roomType
        };
    };

    const submit = () => {
        const isFreeUser = userStore.type === "user-free";

        if (isFreeUser) {
            navigate("/subscriptions");
        } else if (selectedLocation !== "" && !isFreeUser) {
            const body = getBody();

            if (!isEdit) {
                addSearch(body);
            } else {
                body["unique_identifier"] = id;
                updateSearch(body);
            }
        } else {
            showErrorToast(intl.formatMessage({id: "fill-all-fields-search"}));
        }
    };

    const handleProceed = () => {
        if (currentStep === stepsAmount) {
            const body = getBody();
            submitFunnel(body);
        } else {
            handleCurrentStepChange(1);
        }
    };

    return (
        <>
            <ManageSearchContainer>
                <ManageSearchForm>
                    {(currentStep === 1 || currentStep === -1) && (
                        <LocationSection>
                            <div>
                                <SearchDropdownContainer>
                                    <SelectLabel><FormattedMessage id="manage-search-address-label" /></SelectLabel>
                                    <StyledSelect onChange={onLocationChange} value={selectedLocation}>
                                        <option value=""><FormattedMessage id="search-location-placeholder" /></option>
                                        {Object.keys(locations).map(location => (
                                            <option value={location} key={location}>{location}</option>
                                        ))}
                                    </StyledSelect>
                                </SearchDropdownContainer>
                                <SearchDropdownContainer>
                                    <SelectLabel>Radius</SelectLabel>
                                    <StyledSelect
                                        defaultValue={1}
                                        value={radius}
                                        onChange={(e) => setRadius(e.target.value)}
                                    >
                                        {DISTANCES.map(distance => (
                                            <option value={distance} key={distance}>{distance}km</option>
                                        ))}
                                    </StyledSelect>
                                </SearchDropdownContainer>
                            </div>
                            <Map location={locations[selectedLocation]} radius={radius}/>
                        </LocationSection>
                    )}

                    {(currentStep === 2 || currentStep === -1) && (
                        <>
                            <PriceSlider priceRange={priceRange} handlePriceRangeChange={handlePriceRangeChange}/>
                            <DetailsSection>
                                <SearchDropdownContainer>
                                    <SelectLabel><FormattedMessage id="manage-search-bedrooms-label" /></SelectLabel>
                                    <StyledSelect
                                        value={bedrooms}
                                        defaultValue={bedrooms}
                                        onChange={(e) => setBedrooms(e.target.value)}
                                    >
                                        {NR_BEDROOMS.map(amount => (<option value={amount} key={amount}>{amount}</option>))}
                                    </StyledSelect>
                                </SearchDropdownContainer>
                                <SearchDropdownContainer>
                                    <SelectLabel><FormattedMessage id="manage-search-size-label" /></SelectLabel>
                                    <StyledSelect
                                        value={roomSize}
                                        defaultValue={roomSize}
                                        onChange={(e) => setRoomSize(e.target.value)}
                                    >
                                        {SIZES.map(size => (<option value={size} key={size}>{size}m²</option>))}
                                    </StyledSelect>
                                </SearchDropdownContainer>
                            </DetailsSection>

                            <DetailsSection>
                                <SearchDropdownContainer>
                                    <SelectLabel><FormattedMessage id="manage-search-type-label"/></SelectLabel>
                                    <StyledSelect
                                        value={roomType}
                                        defaultValue={roomType}
                                        onChange={(e) => setRoomType(e.target.value)}
                                    >
                                        {TYPES.map(type => (
                                            <option value={type} key={type}>
                                                {intl.formatMessage({id: `${type.replaceAll(" ", "-")}`})}
                                            </option>
                                        ))}
                                    </StyledSelect>
                                </SearchDropdownContainer>
                            </DetailsSection>
                        </>
                    )}

                    {currentStep === -1 && (
                        <CreateUpdateSearch type="button" onClick={submit}>
                            <FormattedMessage id="save" />
                        </CreateUpdateSearch>
                    )}

                    {currentStep !== -1 && (
                        <FunnelStepsButtons>
                            {(currentStep > 1) && (
                                <BackStepBtn type="button" onClick={() => handleCurrentStepChange(-1)}>
                                    <FormattedMessage id="back" />
                                </BackStepBtn>
                            )}
                            <CreateUpdateSearch type="button" onClick={handleProceed}>
                                <FormattedMessage id="next" />
                            </CreateUpdateSearch>
                        </FunnelStepsButtons>
                    )}
                </ManageSearchForm>
            </ManageSearchContainer>
            <ToastContainer/>
        </>
    );
}

export default observer(ManageSearch);