import {observer} from "mobx-react-lite";
import {SubscriptionsSection} from "../home/styles";
import Subscriptions from "../../components/subscriptions";
import {PricesDescription, PricesHeader, PricesText, PricesTitle} from "./styles";
import {FormattedMessage} from "react-intl";
import {PRICES_IMAGE} from "../../constants/images";

function Prices() {
    return (
        <>
            <PricesHeader>
                <img src={PRICES_IMAGE} alt="people-talking"/>
                <PricesText>
                    <PricesTitle><FormattedMessage id="prices-title"/></PricesTitle>
                    <PricesDescription>
                        <FormattedMessage id="prices-description" />
                    </PricesDescription>
                </PricesText>
            </PricesHeader>
            <SubscriptionsSection>
                <Subscriptions/>
            </SubscriptionsSection>
        </>
    );
}

export default observer(Prices);