import {makeAutoObservable} from "mobx";
import {Auth} from "../agent/auth";
import {localeStore} from "./index";
import {authToken, getLocalToken, setAuthToken} from "../constants/api";
import {SearchClient} from "../agent/search";
import {Payment} from "../agent/payment";
import {Subscription} from "../agent/subscription";

export class UserStore {
    authenticated = false;
    email = "";
    firstName = "";
    lastName = "";
    maxSearches = 0;
    searches = [];
    matches = [];
    type = "";

    constructor() {
        makeAutoObservable(this);
    };

    initToken = async () => {
        getLocalToken();

        if (authToken !== "") {
            const res = await Auth.getUserFromToken(authToken);
            this.handleAuthResponse(res);
        }
    };

    initUser(data) {
        if ("auth_token" in data) {
            setAuthToken(data["auth_token"]["token"]);
        }
        this.authenticated = true;

        const userData = "user" in data ? data["user"] : data;
        this.email = userData.email;
        this.firstName = userData["first_name"];
        this.lastName = userData["last_name"];
        this.maxSearches = userData["max_searches"];
        this.searches = userData["searches"];
        this.matches = userData["properties"];
        this.type = userData["type"];
    };

    handleAuthResponse = (res) => {
        if (res === null) {
            return false;
        } else if ("error" in res) {
            return false;
        } else {
            this.initUser(res);
            return true;
        }
    };

    async login(email, password) {
        const body = {
            email: email,
            password: password
        };
        const res = await Auth.login(body);
        return this.handleAuthResponse(res);
    };

    async register(firstName, lastName, email, password) {
        const body = {
            "preferred_language": localeStore.value.toUpperCase(),
            "email": email,
            "password": password,
            "first_name": firstName,
            "last_name": lastName
        };
        const res = await Auth.register(body);
        return this.handleAuthResponse(res);
    };

    async addSearch(body) {
        const res = await SearchClient.add(body);

        if ("error" in res) {
            return [false, res.error.text];
        }

        return [true, ""];
    };

    async updateSearch(body) {
        const res = await SearchClient.update(body);

        if ("error" in res) {
            return [false, res.error.text];
        }

        return [true, ""];
    };

    async deleteSearch(body) {
        const res = await SearchClient.delete(body);

        if ("error" in res) {
            return [false, res.error.text];
        }

        return [true, ""];
    };

    async createPaymentSession(sku) {
        const body = {
            sku: sku
        };
        const res = await Payment.createSession(body);
        return res["checkout_url"] ?? null;
    };

    async getPaymentStatus(sessionId) {
        const res = await Payment.getStatus({sessionId: sessionId});
        return res?.status ?? "incomplete";
    };

    async requestPasswordReset(email) {
        const body = {
            email: email,
        };
        return await Auth.requestPasswordReset(body);
    };

    async resetPassword(token, password) {
        const body = {
            token: token,
            password: password
        };
        return await Auth.resetPassword(body);
    };

    async cancelSubscription() {
        return await Subscription.cancel();
    };

    logout() {
        this.authenticated = false;
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.maxSearches = 0;
        setAuthToken("");
    }
}