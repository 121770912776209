import {FormattedMessage} from "react-intl";
import {PasswordRequirementsContainer, Requirement, Requirements} from "./styles";
import {
    PASSWORD_CAPITAL_LETTER,
    PASSWORD_LENGTH,
    PASSWORD_NUMBER,
    PASSWORD_SPECIAL_CHARACTER
} from "../../constants/helpers";
import {observer} from "mobx-react-lite";

function PasswordRequirements({password, confirmPassword}) {
    return (
        <PasswordRequirementsContainer>
            <FormattedMessage id="auth-signup-password-placeholder" />
            <Requirements>
                <Requirement valid={PASSWORD_LENGTH(password)}>
                    <FormattedMessage id="password-length" />
                </Requirement>
                <Requirement valid={PASSWORD_NUMBER(password)}>
                    <FormattedMessage id="password-number" />
                </Requirement>
                <Requirement valid={PASSWORD_CAPITAL_LETTER(password)}>
                    <FormattedMessage id="password-capitalize" />
                </Requirement>
                <Requirement valid={PASSWORD_SPECIAL_CHARACTER(password)}>
                    <FormattedMessage id="password-special-characters" />
                </Requirement>
                <Requirement valid={password === confirmPassword}>
                    <FormattedMessage id="password-same" />
                </Requirement>
            </Requirements>
        </PasswordRequirementsContainer>
    );
}

export default observer(PasswordRequirements);