import {observer} from "mobx-react-lite";
import {
    AuthContainer,
    AuthForm,
    AuthFormContainer,
    AuthFormItem,
    AuthFormTitle,
    AuthSubmitButton, ForgotPasswordLink
} from "./styles";
import {SITE_LOGO} from "../../constants/images";
import {FormattedMessage, useIntl} from "react-intl";
import CustomInput from "../../components/customInput";
import {useState} from "react";
import {userStore} from "../../stores";
import {useLocation, useNavigate} from "react-router-dom";
import {Bounce, toast, ToastContainer} from "react-toastify";
import {
    PASSWORD_CAPITAL_LETTER,
    PASSWORD_LENGTH,
    PASSWORD_NUMBER,
    PASSWORD_SPECIAL_CHARACTER
} from "../../constants/helpers";
import PasswordRequirements from "../../components/passwordRequirements";

function Authentication() {
    const location = useLocation();
    const navigate = useNavigate();
    const intl = useIntl();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPasswordTip, setShowPasswordTip] = useState(false);
    const isRegister = location.pathname.includes("register");

    const onAuthSuccess = () => {
        navigate("/profile");
    };

    const onAuthFailure = (msg) => {
        toast.error(msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    };

    const login = () => {
        userStore.login(email, password).then(success => {
            if (success) onAuthSuccess();
            else onAuthFailure(intl.formatMessage({id: "login-error-msg"}));
        });
    };

    const register = () => {
        const samePassword = password === confirmPassword;
        const validPassword = PASSWORD_LENGTH(password) && PASSWORD_NUMBER(password) && PASSWORD_CAPITAL_LETTER(password) && PASSWORD_SPECIAL_CHARACTER(password);

        if (validPassword && samePassword) {
            userStore.register(firstName, lastName, email, password).then(success => {
                if (success) onAuthSuccess();
                else onAuthFailure(intl.formatMessage({id: "register-error-msg"}));
            });
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (isRegister) register();
        else login();
    };

    return (
        <>
            <AuthContainer>
                <AuthFormContainer>
                    <img src={SITE_LOGO} width={100} alt="form-site-logo" />
                    <AuthFormTitle><FormattedMessage id={isRegister ? "auth-register" : "auth-login"} /></AuthFormTitle>

                    <AuthForm onSubmit={submitForm}>
                        {isRegister && (
                            <>
                                <AuthFormItem>
                                    <CustomInput
                                        placeholder={intl.formatMessage({id: "auth-first-name-placeholder"})}
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </AuthFormItem>
                                <AuthFormItem>
                                    <CustomInput
                                        placeholder={intl.formatMessage({id: "auth-last-name-placeholder"})}
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </AuthFormItem>
                            </>
                        )}
                        <AuthFormItem>
                            <CustomInput
                                placeholder={intl.formatMessage({id: "auth-email-placeholder"})}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </AuthFormItem>
                        <AuthFormItem>
                            <CustomInput
                                onFocus={() => setShowPasswordTip(true)}
                                onBlur={() => setShowPasswordTip(false)}
                                placeholder={intl.formatMessage({id: "auth-password-placeholder"})}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {!isRegister && (
                                <ForgotPasswordLink to="/forgot-password">
                                    <FormattedMessage id="forgot-password" />
                                </ForgotPasswordLink>
                            )}
                            {(isRegister && showPasswordTip) && (
                              <PasswordRequirements password={password} confirmPassword={confirmPassword} />
                            )}
                        </AuthFormItem>
                        {isRegister && (
                            <AuthFormItem>
                                <CustomInput
                                    onFocus={() => setShowPasswordTip(true)}
                                    onBlur={() => setShowPasswordTip(false)}
                                    placeholder={intl.formatMessage({id: "auth-confirm-password-placeholder"})}
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </AuthFormItem>
                        )}

                        <AuthSubmitButton type="submit">
                            <FormattedMessage id={isRegister ? "auth-register" : "auth-login"} />
                        </AuthSubmitButton>
                    </AuthForm>
                </AuthFormContainer>
            </AuthContainer>
            <ToastContainer />
        </>
    );
}

export default observer(Authentication);