import {observer} from "mobx-react-lite";
import {AllGuarantees, Guarantee, GuaranteesContainer} from "./styles";
import {CHECK} from "../../constants/images";
import {FormattedMessage} from "react-intl";

function Guarantees() {
    return (
        <GuaranteesContainer>
            <AllGuarantees>
                <Guarantee>
                    <img src={CHECK} alt="check-mark" />
                    <p><FormattedMessage id="guarantee-one" /></p>
                </Guarantee>
                <Guarantee>
                    <img src={CHECK} alt="check-mark" />
                    <p><FormattedMessage id="guarantee-two" /></p>
                </Guarantee>
                <Guarantee>
                    <img src={CHECK} alt="check-mark" />
                    <p><FormattedMessage id="guarantee-three" /></p>
                </Guarantee>
            </AllGuarantees>
        </GuaranteesContainer>
    );
}

export default observer(Guarantees);