import styled from "styled-components";

export const GuaranteesContainer = styled.section`
    display: flex;
    padding: 40px 0;
    background: #f5f5f5;
    justify-content: center;
    width: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AllGuarantees = styled.div`
    display: flex;
`;

export const Guarantee = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    
    img {
        margin-right: 10px;
    }
    
    p {
        font-weight: 600;
    }
`;