import styled from "styled-components";

export const SubscriptionsSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 40px 0;
    background: #f5f5f5;
    align-items: center;
    
    @media (max-width: 768px) {
        background: white;
    }
`;

export const SubscriptionsSectionHeader = styled.h2`
    color: #333333;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;

    #desktop {
        display: block;
    }

    #mobile {
        display: none;
    }

    @media (max-width: 768px) {
        #desktop {
            display: none;
        }
        
        #mobile {
            display: block;
        }
    }
`;