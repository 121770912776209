import {observer} from "mobx-react-lite";
import {ManagementBtn, ManagementContainer} from "./styles";
import {FormattedMessage, useIntl} from "react-intl";
import {userStore} from "../../stores";

function Management() {
    const intl = useIntl();

    const cancelSubscription = () => {
        // eslint-disable-next-line no-restricted-globals
        const yes = confirm(intl.formatMessage({id: "cancel-subscription-confirm"}));

        if (yes) {
            userStore.cancelSubscription().then();
        }
    }

    return (
        <ManagementContainer>
            <ManagementBtn onClick={cancelSubscription}><FormattedMessage id="cancel-subscription" /></ManagementBtn>
        </ManagementContainer>
    );
}

export default observer(Management);