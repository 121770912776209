import styled from "styled-components";

export const ManageSearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;

    @media (max-width: 768px) {
        margin: 0;
    }
`;

export const ManageSearchForm = styled.form`
    //margin: auto;
    height: 100%;
    width: 1250px;
    display: flex;
    flex-flow: column;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
    }
`;

export const LocationSection = styled.section`
    display: flex;
    width: 100%;
    
    div:nth-child(1) {
        flex: 1;
    }
    
    div {
        margin: 8px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const DetailsSection = styled.section`
    display: flex;
    width: 100%;

    div {
        margin: 8px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SearchDropdownContainer = styled.div`
    display: grid;
    border-radius: 15px;
    padding: 8px 15px;
    border: 1px solid #f2f2f2;
    flex: 1;
`;

export const SelectLabel = styled.label`
    font-size: 1.3rem;
    margin-left: 5px;
`;

export const StyledSelect = styled.select`
    font-size: 1.6rem;
    border: none;
    flex: 1;
    
    &:focus {
        outline: none;
    }
`;

export const CreateUpdateSearch = styled.button`
    width: 100%;
    background: #214697;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    margin: 32px 0;
`;

export const BackStepBtn = styled(CreateUpdateSearch)`
    color: #214697;
    background: transparent;
    border: 1px solid #214697;
`;

export const FunnelStepsButtons = styled.div`
    display: flex;
    margin-top: auto;
    
    button {
        margin: 32px 8px 20px 8px;
    }
`;