export const DISTANCES = [
  1, 2, 3, 4, 5, 6, 7, 8
];

export const NR_BEDROOMS = [
    1, 2, 3, 4
];

export const SIZES = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100
];

export const TYPES = [
  "furnished", "not furnished", "no preference"
];