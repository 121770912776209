import {observer} from "mobx-react-lite";
import {FormattedMessage} from "react-intl";
import {userStore} from "../../stores";
import {EmptyTable, MatchImage, MatchUrl, RowAction, RowActions, SearchesTable} from "../searches/styles";
import {OPEN_LINK} from "../../constants/images";

function Matches() {
    const openLink = (url) => {
        window.open(url, '_blank');
    };

    const formatName = (name) => {
        let splitName = name.split(" ");
        splitName.forEach((split, i) => {
            splitName[i] = split.charAt(0).toUpperCase() + split.slice(1);
        });

        return splitName.join(" ");
    };

    return (
        <SearchesTable>
            <thead>
            <tr>
                <th><FormattedMessage id="table-header-image"/></th>
                <th>Details</th>
                <th><FormattedMessage id="table-header-found-on"/></th>
            </tr>
            </thead>
            <tbody>
            {userStore.matches.length === 0 && (
                <EmptyTable>
                    <FormattedMessage id="table-no-matches"/>
                </EmptyTable>
            )}
            {userStore.matches.map((match, i) => (
                <tr>
                    <td>
                        {match.picture && (<MatchImage src={match.picture} alt={`match-image-${i}`}/>)}
                        {!match.picture && (<span>╳</span>)}
                    </td>
                    <td>

                        <li>{formatName(match.address)}, {formatName(match.city)}</li>
                        {match.amount_bedrooms && (
                            <li>
                                {match["amount_bedrooms"]}
                                <FormattedMessage id="table-rooms-cell" />
                            </li>
                        )}
                    </td>
                    <RowActions>
                        <RowAction onClick={() => openLink(match.url)}>
                            <MatchUrl>{new URL(match.url).hostname}</MatchUrl>
                            <img src={OPEN_LINK} alt="open-link"/>
                        </RowAction>
                    </RowActions>
                </tr>
            ))}
            </tbody>
        </SearchesTable>
    );
}

export default observer(Matches);