import styled from "styled-components";
import {ActivateBtn} from "../../components/subscriptionCard/styles";

export const ProfileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: auto;

    @media (max-width: 768px) {
        margin: 32px 0 0 0;
    }
`;

export const ProfileContent = styled.div`
    margin: auto;
    width: 65%;
    display: flex;

    @media (max-width: 768px) {
        width: 90%;
        flex-flow: column;
    }
    
    div:nth-child(2) {

        @media (max-width: 768px) {
            width: 100%;
            margin: 0;
        }
    }
`;

export const CreateSearchBtn = styled.button`
    border-radius: 25px;
    border: none;
    width: fit-content;
    padding: 10px 20px;
    color: white;
    background: #1b72e8;
    font-size: 1.2rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px 0;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 1.6rem;
    }
    
    span {
        height: fit-content;
        font-size: 1.2rem;
        margin-right: 8px;
    }
`;

export const RightSection = styled.div`
    margin-left: 16px;
`;

export const ProfileSection = styled.section`
    border-radius: 25px;
    flex: 1;
    margin-bottom: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
`;

export const ProfileSectionHeader= styled.div`
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    background: #f8f9fa;
    padding: 10px 25px;
    font-size: 1.5rem;
`;

export const FreeUserBanner = styled.div`
    width: 100%;
    padding: 20px 15%;
    margin-bottom: 50px;
    background: #FCE8E6;
    display: flex;
    align-items: center;
    
    b {
        font-weight: 600;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        
        #desktop-user-free-ext {
            display: none;
        }
    }
`;

export const FreeUserActivateBtn = styled(ActivateBtn)`
    margin-left: auto;
    background: #C5221F;

    @media (max-width: 768px) {
        margin: 10px 0 0 0;
    }
`;