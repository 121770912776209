import {EN_FLAG, NL_FLAG} from "./images";

export const LANGUAGES = [
    {
        icon: NL_FLAG,
        label: "Nederlands",
        value: "nl"
    },
    {
        icon: EN_FLAG,
        label: "English",
        value: "en"
    }
];