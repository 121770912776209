import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDNgHLVV5g1u4hFBeYmp5R-W8KklRs2wfE",
  authDomain: "the-rent-friend.firebaseapp.com",
  projectId: "the-rent-friend",
  storageBucket: "the-rent-friend.appspot.com",
  messagingSenderId: "432935149505",
  appId: "1:432935149505:web:d2d56589daf360cabf9940",
  measurementId: "G-99B838ZNVM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const log = (eventName, values) => {
  logEvent(analytics, eventName, values);
};