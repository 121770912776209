export const BASE_URL = "https://user.therentfriend.nl";
export const WORKER_URL = "https://trf.kouhai.workers.dev";
export let authToken = "";

export const setAuthToken = (token) => {
    localStorage.setItem("authToken", token);
    authToken = token;
};

export const getLocalToken = () => {
    const token = localStorage.getItem("authToken");
    authToken = token !== undefined && token !== null ? token : "";
};