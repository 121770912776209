import {observer} from "mobx-react-lite";
import {AllQuestions, FaqContainer, FaqTitle} from "./styles";
import {FormattedMessage} from "react-intl";
import Question from "../question";

function Faq({questionNumbers}) {

    return (
        <FaqContainer>
            <FaqTitle>
                <FormattedMessage id="faq-title" />
            </FaqTitle>

            <AllQuestions>
                {questionNumbers.map((q) => (
                    <Question number={q} key={`question-${q}`} />
                ))}
            </AllQuestions>
        </FaqContainer>
    );
}

export default observer(Faq);