import {observer} from "mobx-react-lite";
import {Circle, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {StyledMapContainer} from "./styles";
import {useEffect} from "react";

const ChangeView = ({ center, zoom }) => {
    const map = useMap();

    useEffect(() => {
        map.setView(center, zoom);
    }, [center, zoom, map]);

    return null;
};

function Map({location, radius}) {
    const position = [location ? location.lat : 52.377956, location ? location.long : 4.897070];

    return (
        <StyledMapContainer
            center={position}
            zoom={10}
            scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Circle
                center={position}
                pathOptions={{ fillColor: "blue" }}
                radius={parseInt(radius) * 1000}
            />
            <ChangeView center={position} zoom={10} />
        </StyledMapContainer>
    );
}

export default observer(Map);