import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
    @media (max-width: 768px) {
        .modal-content {
            height: calc(100vh - 8px);
        } 
    }
`;

export const FunnelModalHeader = styled.div`
    display: grid;
    padding: 8px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    align-items: center;
    
    *:nth-child(2) {
        margin: auto;
    }
`;

export const StepIndicator = styled.div`
    padding: 8px 16px;
    background: #214697;
    border-radius: 25px;
    width: fit-content;
    color: white;
`;