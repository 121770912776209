import styled from "styled-components";

export const StyledInput = styled.input`
    border-radius: 25px;
    border: 1px solid #254ea7;
    padding: 8px 15px;
    width: 100%;
    font-size: 1.6rem;
    
    &:focus {
        outline: none;
        border: 1px solid #214697;
    }
`;