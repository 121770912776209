import styled from "styled-components";

export const FaqTitle = styled.h2`
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
`;

export const FaqContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 40px 0;
    align-items: center;
    background: #f5f5f5;
`;

export const AllQuestions = styled.div`
    width: 60%;

    @media (max-width: 768px) {
        width: 90%;
    }
`;