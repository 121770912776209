import {observer} from "mobx-react-lite";
import {
    MobileAmountSearches,
    MobileSubSearchesInfo, MobileSubPrice,
    MobileSubPriceInfo,
    MobileSubscriptionCardContainer, MobileSubInfo
} from "./mobileStyles";
import {FormattedMessage, useIntl} from "react-intl";
import {SEARCH_HOUSE} from "../../constants/images";
import {PerMonth} from "./styles";

function MSubcriptionCard({value, selectedSub, handleSelectedSubChange}) {
    const intl = useIntl();
    const price = parseFloat(value["stripe_default_price"]).toFixed(2).toString();
    const inputId = `${value.sku}-radio`;

    const formatSearchesText = () => {
        let queriesText = intl.formatMessage({id: "subscription-card-months"});
        const searchesAmount = value["min_months"];

        if (searchesAmount === 1) {
            queriesText = queriesText.replaceAll("s", "").replaceAll("en", "")
        }

        return `${searchesAmount} ${queriesText}`
    };

    return (
        <MobileSubscriptionCardContainer
            onClick={() => handleSelectedSubChange(value)}
            selected={selectedSub && selectedSub.sku === value.sku}
        >
            <input
                type="radio"
                name="selected-sku"
                id={inputId}
                checked={selectedSub && selectedSub.sku === value.sku}
            />

            <MobileSubSearchesInfo>

                <MobileAmountSearches>
                    <img src={SEARCH_HOUSE} />
                    <p>
                        <b>{formatSearchesText()}</b>
                    </p>
                </MobileAmountSearches>
            </MobileSubSearchesInfo>

            <MobileSubPriceInfo>
                <MobileSubInfo>
                    <MobileSubPrice>
                        <span className="currency">€</span>
                        <p>{price.replaceAll(".", ",")}</p>
                    </MobileSubPrice>
                    <PerMonth>
                        <FormattedMessage id="subscription-card-each-month"/>
                    </PerMonth>
                </MobileSubInfo>
            </MobileSubPriceInfo>
        </MobileSubscriptionCardContainer>
    );
}

export default observer(MSubcriptionCard);