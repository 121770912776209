import styled from "styled-components";

export const PriceSliderContainer = styled.div`
    margin: 32px 0;
`;

export const PriceRangeValue = styled.span`
    margin: 0 16px;
`;

export const PriceRangeLabel = styled.label`
    font-size: 1.3rem;
    margin: 0 16px 8px 16px;
`;

export const RangeContainer = styled.div`
    display: flex;
    align-items: center;
`;