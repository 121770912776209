import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/home";
import "./App.css";
import {observer} from "mobx-react-lite";
import {IntlProvider} from "react-intl";
import {localeStore, userStore} from "./stores";
import {messages} from "./constants/locale";
import Layout from "./components/layout";
import Authentication from "./pages/authentication";
import {useEffect, useState} from "react";
import Profile from "./pages/profile";
import ManageSearch from "./pages/manageSearch";
import Prices from "./pages/prices";
import FAQ from "./pages/FAQ";
import Contact from "./pages/contact";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";

function App() {
    const [loading, setLoading] = useState(true);
    const openRoutes = [
        {path: "/home", element: <Home/>},
        {path: "/subscriptions", element: <Prices/>},
        {path: "/faq", element: <FAQ/>},
        {path: "/contact", element: <Contact/>},
        {path: "*", element: <Home/>},
    ];
    const nonAuthRoutes = [
        {path: "/login", element: <Authentication/>},
        {path: "/register", element: <Authentication/>},
        {path: "/forgot-password", element: <ForgotPassword/>},
        {path: "/password-reset", element: <ResetPassword/>}
    ];

    const authRoutes = [
        {path: "/profile", element: <Profile/>},
        {path: "/create", element: <ManageSearch/>},
        {path: "/edit/:id", element: <ManageSearch/>}
    ];

    const [router, setRouter] = useState(null);
    const handleRefreshRouter = () => {
        setRouter(createBrowserRouter(
            [{
                element: <Layout/>,
                children: [
                    ...openRoutes,
                    ...(userStore.authenticated ? authRoutes : nonAuthRoutes)
                ]
            }],
            // {
            //     basename: `/${localeStore.value}`
            // }
        ));
    };

    useEffect(() => {
        userStore.initToken().then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        handleRefreshRouter();
    }, [localeStore.value, userStore.authenticated]);

    if (!loading && router && localeStore.value) {
        return (
            <IntlProvider locale={localeStore.value} defaultLocale="nl" messages={messages[localeStore.value]}>
                <div id="container">
                    <RouterProvider router={router}/>
                </div>
            </IntlProvider>
        );
    } else {
        return <></>
    }
}

export default observer(App);
