import {authToken, WORKER_URL} from "../constants/api";

export const Payment = {
    createSession: async (body) => {
        try {
            const res = await fetch(`${WORKER_URL}/subscriptions/create-session`, {
                method: "POST",
                headers: {
                    'x-auth-token': authToken
                },
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    },
    getStatus: async (body) => {
        try {
            const res = await fetch(`${WORKER_URL}/status`, {
                method: "POST",
                body: JSON.stringify(body)
            });

            return await res.json();
        } catch (e) {
            return null;
        }
    }
}